/// <reference path="o365.pwa.declaration.sw.routehandlers.api.pwa.d.ts" />

import type { IO365ServiceWorkerGlobalScope } from 'o365.pwa.declaration.sw.O365ServiceWorkerGlobalScope.d.ts';

declare var self: IO365ServiceWorkerGlobalScope;

(() => {
    const { ApiPwaStrategy } = self.o365.importScripts<typeof import('o365.pwa.declaration.sw.strategies.api.pwa.strategy.d.ts')>("o365.pwa.modules.sw.strategies.api.pwa.strategy.ts");
    
    function initializeApiPwaRouteHandler() {
        self.o365.registerRoute(
            new RegExp('\/api\/pwa\/offline-sync\/*'),
            new ApiPwaStrategy({
                mode: 'OFFLINE-SYNC'
            }),
            'POST'
        );
        self.o365.registerRoute(
            new RegExp('\/api\/pwa\/online-sync\/*'),
            new ApiPwaStrategy({
                mode: 'ONLINE-SYNC'
            }),
            'POST'
        );
        self.o365.registerRoute(
            new RegExp('\/api\/pwa\/truncate\/*'),
            new ApiPwaStrategy({
                mode: 'TRUNCATE'
            }),
            'POST'
        );
    }

    self.o365.exportScripts<typeof import('o365.pwa.declaration.sw.routehandlers.api.pwa.d.ts')>({ initializeApiPwaRouteHandler });
})();
